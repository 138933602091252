import '@nike/eds/dist/index.css'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security, LoginCallback } from '@okta/okta-react'
import React, { useCallback } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { oktaAuth } from './config/OktaConfig'
import { LoginError } from './login-error/LoginError'
import { RequiredAuth } from './shared/AuthenticatedRoute'
import '@nike/eds/dist/index.css'
import { VersionOverview } from './pages/VersionOverview'
import { createBrowserHistory } from 'history'
import TrackUserId from './shared/analytics/identify'
import {VersionDetail} from "./pages/VersionDetail"

const history = createBrowserHistory()

const AppInSecureContext = () => {

    return  <>
                <TrackUserId />
                <Routes>
                    <Route element={<RequiredAuth/>}>
                        <Route path="/version-overview/*" element={<VersionOverview />} />
                        <Route path="/version-overview/details/:id" element={<VersionDetail />} />
                        <Route path="/" element={<Navigate to="/version-overview"/>} />
                    </Route>
                    <Route path="/implicit/callback" element={<LoginCallback errorComponent={LoginError} />}/>
                </Routes>
            </>
}

const App = () => {
    const navigate = useNavigate()
    const restoreOriginalUri = useCallback(
        (_oktaAuth: any, originalUri: string) => {
            navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
        },
        [navigate]
    )

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <React.Fragment>
              <AppInSecureContext />
            </React.Fragment>
        </Security>
    );
}

export default App
