import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    detail,
    list,
} from "../../graphql/queries";

export class VersionManagementService {

    constructor(private appsyncService: AppsyncService) {
    }

    async list(nextToken: string): Promise<any> {
        let query = this.appsyncService.appSyncClient.query({
                query: gql(list), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                }
            }
        );
        return query;
    }

    async detail(hashKey: string, sortKey: string): Promise<any> {
        let query = this.appsyncService.appSyncClient.query({
                query: gql(detail), fetchPolicy: 'network-only', variables: {
                    hashKey: hashKey,
                    sortKey: sortKey
                }
            }
        );
        return query;
    }

}
