import React, { useEffect, useState } from 'react'
import { Skeleton, Table, TableCell, TableHeading } from '@nike/eds'
import { AppsyncService } from '../services/AppsyncService'
import { oktaAuth } from '../../config/OktaConfig'
import { commits } from '../services/GithubService'
import {useGithubContext} from '../../shared/githubContext'

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)

const VersionDetailCommits = ({searchResult}) => {
    const headers = [
        {Header: "SHA"},
        {Header: "Author"},
        {Header: "Message"}
    ];
    const cellStyle = {
        color: "orange"
    }
    let [data, setData] = useState([]);

    const githubApiToken = useGithubContext();

    useEffect(() => {
        let behind = 0;
        const fetchGitHubData = async () => {
            var result = await commits(githubApiToken, searchResult);
            if (result.length) {
                setData(result);
            }
        }
        fetchGitHubData();
    }, [searchResult, githubApiToken]);

    return (
        <div className="eds-spacing--ml-20 eds-spacing--mr-20">
            <div className="eds-grid eds-grid--m-cols-6 eds-elevation--4">
                <Table className="eds-grid--m-col-6 eds-grid--item-align-start">
                    <thead>
                    <tr>
                        {headers.map(column => (<TableHeading key={column.Header}>{column.Header}</TableHeading>))}
                    </tr>
                    </thead>
                    <tbody>
                    {!data.length &&
                        <>
                            <tr>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            </tr>
                        </>
                    }
                    {data.length && data.map(commit => (
                        searchResult.githubCommitSha === commit.sha ? (
                            <tr>
                                <TableCell style={cellStyle}>{commit.sha}</TableCell>
                                <TableCell style={cellStyle}>{commit.author.login}</TableCell>
                                <TableCell style={cellStyle}>{commit.commit.message}</TableCell>
                            </tr>
                        ) : (
                            <tr>
                                <TableCell>
                                    <a className="eds-link" target="_blank"
                                       href={"https://github.com/" + searchResult.githubRepo + "/customs-inventory." + searchResult.hashKey + "/commit/" + commit.sha}>{commit.sha}</a>
                                </TableCell>
                                <TableCell>{commit.author.login}</TableCell>
                                <TableCell>{commit.commit.message}</TableCell>
                            </tr>
                        )
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );

}

export {VersionDetailCommits};
