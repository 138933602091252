/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const detail = /* GraphQL */ `
  query Detail($hashKey: String!, $sortKey: String!) {
    detail(hashKey: $hashKey, sortKey: $sortKey) {
      items {
        hashKey
        sortKey
        version
        githubRepo
        githubCommitSha
      }
      nextToken
    }
  }
`;
export const list = /* GraphQL */ `
  query List($limit: Int, $nextToken: String) {
    list(limit: $limit, nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        version
        githubRepo
        githubCommitSha
      }
      nextToken
    }
  }
`;
