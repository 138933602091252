import React, {useEffect, useState} from "react";
import {commits} from "../services/GithubService";
import {CommitInfo} from "../../model/CommitInfo";
import {Chip, Icon} from "@nike/eds";
import {useGithubContext} from '../../shared/githubContext'

const GithubStatus = ({searchResult}) => {

    let [behind, setBehind] = useState(0);

    const githubApiToken = useGithubContext();

    useEffect(() => {
        let behind = 0;
        const fetchGitHubData = async () => {
            var result = await commits(githubApiToken, searchResult);
            if (result.length) {
                for (let commitInfo of result) {
                    if (searchResult.githubCommitSha === (commitInfo as CommitInfo).sha) {
                        break;
                    }
                    behind--;
                }
            }
            setBehind(behind);

        }
        fetchGitHubData();
    }, [searchResult, githubApiToken]);

    return (
        <Chip as="button" size="small" beforeSlot={<Icon name="Shareaos"/>}>{behind}</Chip>
    );
}

export {GithubStatus};
