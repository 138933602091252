import React, {useState, useEffect} from "react";
import { Header } from '../shared/header/Header'
import { Footer } from '../shared/footer/Footer'
import { oktaAuth } from '../config/OktaConfig'
import { VersionOverviewTable } from "../components/overview/VersionOverviewTable";
import { AppsyncService } from "../components/services/AppsyncService";
import { VersionManagementService } from "../components/services/VersionManagementService";
import useSessionStorage from "../hooks/useSessionStorage";

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

export function VersionOverview() {

    const [loading, setLoading] = useState(false);
    const [ pagingTokensSession, setPagingTokens ] = useSessionStorage("pagingTokens", []);
    let [searchResults, setSearchResults] = useState([]);
    let pagingTokens = pagingTokensSession.value;

    useEffect(() => {
        const fetchVersionData = async () => {
            setLoading(true);

            var result = await versionManagementService.list(pagingTokens.at(-1));
            try {
                setLoading(false);
                setSearchResults(result.data.list.items)
                setPagingTokens(pagingTokens.concat(result.data.listRequests.nextToken))
            } catch(error) {
                setLoading(false);
            }
        }

        fetchVersionData();
    }, []);

    return (
        <div>
            <Header />
            <br />
            <VersionOverviewTable searchResults={searchResults} loading={loading}/>
            <Footer />
        </div>
    )
}
