import { createContext, useContext, useEffect, useState } from 'react'
import { VersionManagementService } from '../components/services/VersionManagementService'
import { AppsyncService } from '../components/services/AppsyncService'
import { oktaAuth } from '../config/OktaConfig'

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

const GithubContext = createContext(null);

const useGithubContext = () => {
    return useContext(GithubContext);
}

const SetGithubContext = ({children} : Readonly<{children:React.ReactNode}>) => {

    let [githubApiToken, setGithubApiToken] = useState();

    useEffect(() => {
        const getGithubApiToken = async () => {
            var result = await versionManagementService.config('GITHUB_API_TOKEN');
            setGithubApiToken(result.data.config.items[0].propValue);

        }
        getGithubApiToken();
    }, []);

    return <GithubContext.Provider value={githubApiToken}>{githubApiToken ? children : undefined}</GithubContext.Provider>
}

export {useGithubContext, SetGithubContext};
