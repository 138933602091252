import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import ReactGA from 'react-ga4'

const setUserId = (userid) => {
  ReactGA.set({ userId: userid })
}

const TrackUserId = (props) => {
    const { oktaAuth, authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const userid = isAuthenticated ? authState?.accessToken?.claims?.sub : undefined;

    useEffect(() => {
        console.log("userid", userid);
        if (userid) {
            setUserId(userid);
        }
    }, [ userid ]);

    return null;
}

export default TrackUserId;