import moment from 'moment';
import {VersionManagement} from "../../graphql/api";

export async function commits(githubApiToken, searchResult : VersionManagement) : Promise<[]> {

    let daysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD[T]hh:mm:ss');

    if (searchResult.githubRepo && searchResult.hashKey) {
        return await fetch(`https://api.github.com/repos/${searchResult.githubRepo}/customs-inventory.${searchResult.hashKey}/commits?since=since=${daysAgo}`, {
            headers: {
                'Authorization': `Bearer ${githubApiToken}`,
            }
        }).then(res => res.json());
    } else {
        return [];
    }

}

export async function commit(githubApiToken, searchResult : VersionManagement) : Promise<{}> {

    if (searchResult.githubRepo && searchResult.hashKey && searchResult.githubCommitSha) {
        return await fetch(`https://api.github.com/repos/${searchResult.githubRepo}/customs-inventory.${searchResult.hashKey}/commits/${searchResult.githubCommitSha}`, {
            headers: {
                'Authorization': `Bearer ${githubApiToken}`,
            }
        }).then(res => res.json());
    } else {
        return {};
    }

}
