import React, { useState, useEffect, useContext } from 'react'
import { Header } from '../shared/header/Header'
import { Footer } from '../shared/footer/Footer'
import { oktaAuth } from '../config/OktaConfig'
import { VersionOverviewTable } from '../components/overview/VersionOverviewTable'
import { AppsyncService } from '../components/services/AppsyncService'
import { VersionManagementService } from '../components/services/VersionManagementService'
import useSessionStorage from '../hooks/useSessionStorage'
import { OverviewSearchForm } from '../components/search/OverviewSearchForm'
import { RequestedSearchForm } from '../model/RequestSearchForm'
import SnackbarContext from '../providers/snackbar-context'
import {SetGithubContext} from '../shared/githubContext'

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

export function VersionOverview() {

    const snackbarCtx = useContext(SnackbarContext)
    const [loading, setLoading] = useState(false)
    const [pagingTokensSession, setPagingTokens] = useSessionStorage('pagingTokens', [])
    let [searchResults, setSearchResults] = useState([])
    let pagingTokens = pagingTokensSession.value
    const [currentSearchForm, setCurrentSearchForm] = useState({})

    const searchHandler = (requestedSearchForm: RequestedSearchForm, restore: boolean) => {
        setCurrentSearchForm(requestedSearchForm)
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1)
        } else {
            pagingTokens = []
        }
        searchResults = []
        fetchData(requestedSearchForm, restore)
    }

    const fetchData = (requestedSearchForm: RequestedSearchForm, restore : boolean = false) => {
        setLoading(true)

        if (requestedSearchForm.project === '') {
            versionManagementService.list(pagingTokens.at(-1)).then((result) => {
                setLoading(false)
                setSearchResults(result.data.list.items)
                if (!restore) {
                    setPagingTokens(pagingTokens.concat(result.data.list.nextToken))
                }
            }).catch((error) => {
                setLoading(false)
                snackbarCtx.displayMsg(error.message, 'error')
            })
        } else if (requestedSearchForm.environment === '') {
            versionManagementService.listByProject(requestedSearchForm.project, pagingTokens.at(-1)).then((result) => {
                setLoading(false)
                setSearchResults(result.data.listByProject.items)
                setPagingTokens(pagingTokens.concat(result.data.listByProject.nextToken))
            }).catch((error) => {
                setLoading(false)
                snackbarCtx.displayMsg(error.message, 'error')
            })
        } else {
            versionManagementService.listByProjectAndEnvironment(requestedSearchForm.project, requestedSearchForm.environment, pagingTokens.at(-1)).then((result) => {
                setLoading(false)
                setSearchResults(result.data.listByProjectAndEnvironment.items)
                setPagingTokens(pagingTokens.concat(result.data.listByProjectAndEnvironment.nextToken))
            }).catch((error) => {
                setLoading(false)
                snackbarCtx.displayMsg(error.message, 'error')
            })
        }

    }

    useEffect(() => {
        fetchData({ project: 'version-management', environment: '' })
    }, [])

    return <SetGithubContext>
                <div>
                    <Header />
                    <br />
                    <OverviewSearchForm onSearch={searchHandler} />
                    <VersionOverviewTable searchResults={searchResults} loading={loading} />
                    <Footer />
                </div>
            </SetGithubContext>
}
